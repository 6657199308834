<template>
  <wrapper class="empty-state">
    <img
      alt="empty"
      class="empty-state__image"
      :src="image"
    >
    <p class="empty-state__warning">
      {{ warning }}
    </p>
    <p class="empty-state__comment">
      {{ comment }}
    </p>
    <div
      v-if="$slots.default"
      class="empty-state__action"
    >
      <slot />
    </div>
  </wrapper>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    image: {
      type: String,
      required: true,
    },
    warning: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass">
.empty-state
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: $size-l 0

  +mq-m--mf
   padding-bottom: $size-xxl

  &__image
    max-width: 200px

    +mq-s--mf
      max-width: 320px

  &__warning
    font-size: $font-size-xs
    font-weight: $font-weight-semi-bold
    text-align: center
    margin-top: $size-l

    +mq-xs--mf
      font-size: $font-size-m

    +mq-s--mf
      font-size: $font-size-heading-4-small

    +mq-m--mf
      font-size: $font-size-heading-4

  &__comment
    font-size: $font-size-xs
    text-align: center
    color: $color-ink-light
    margin-top: $size-xs
    max-width: 600px

    +mq-xs--mf
      font-size: $font-size-s

    +mq-s--mf
      font-size: $font-size-m

  &__action
    margin-top: $size-l
</style>
