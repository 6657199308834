<template>
  <box
    :background="headerBackground"
    :border-radius="$tokens.size_s"
    class="assessment-card"
    :class="{ '--loading': loading }"
    padding="0"
  >
    <div class="assessment-card__header">
      <h6 class="assessment-card__header__info">
        <template v-if="!loading">
          <img
            class="assessment-card__header__info__icon"
            :src="iconSource"
          >
          <div class="header-info__title">
            {{ assessment.exams[0].lecture.name }}
          </div>
        </template>
        <template v-else>
          <skeleton-loader
            v-if="!$mq.includes('small')"
            :height="$tokens.size_l"
            :width="$tokens.size_l"
          />
          <skeleton-loader width="100px" />
        </template>
      </h6>
      <icon
        v-if="!loading "
        stroke="white"
        type="arrow-right"
      />
    </div>
    <div class="assessment-card__body">
      <div class="assessment-card__body__info">
        <div class="info-status">
          <SBadge
            v-if="!loading"
            class="info-status__badge"
            :icon-left="statusBadge.icon"
            :variation="statusBadge.variation"
          >
            {{ statusBadge.text }}
          </SBadge>
          <skeleton-loader
            v-else
            :height="$tokens.size_m"
            width="70px"
          />
        </div>
        <h6 class="info-title">
          <template v-if="!loading">
            {{ assessment.name }}
          </template>
          <skeleton-loader
            v-else
            class="info-title"
            :height="$tokens.size_l"
            width="180px"
          />
        </h6>
      </div>

      <div class="assessment-card__body__application-range">
        <skeleton-loader
          v-if="loading"
          :height="$tokens.size_m"
          width="150px"
        />
        <template v-else>
          {{ applicationDate }}
        </template>
      </div>
    </div>
  </box>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'AssessmentCard',
  mixins: [mediaQueries],
  props: {
    assessment: {
      type: Object,
      required: true,
      default: () => ({
        name: '',
        lecture: '',
        grade: '',
        status: '',
        createdAt: '',
        color: '',
      }),
    },
    loading: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },
  computed: {
    formattedChapter() {
      const { chapter } = this.assessment
      const tokens = chapter.title.split(' ')
      const MAX_FIRST_WORD_SIZE = 3

      return `${tokens[0].substr(0, MAX_FIRST_WORD_SIZE)}. ${tokens[1]}`
    },
    hasOptions() {
      return this.options.length
    },
    headerBackground() {
      return !this.loading
        && this.assessment.exams[0].lecture
        && this.assessment.exams[0].lecture.subject
        ? this.assessment.exams[0].lecture.subject.color
        : this.$tokens.color_ink_light
    },
    iconSource() {
      const { lecture } = this.assessment.exams[0]

      return lecture && lecture.subject
        // eslint-disable-next-line import/no-dynamic-require, global-require
        ? require(`../assets/${lecture.subject.icon.split('icons/')[1]}`)
        : ''
    },
    statusBadge() {
      return {
        NOT_STARTED: {
          variation: 'primary',
          icon: 'clock',
          text: this.$t('personalAssessment.assessmentCard.status.scheduled'),
        },
        APPLYING: {
          variation: 'primary',
          icon: 'file-text',
          text: this.$t('personalAssessment.assessmentCard.status.available'),
        },
        DONE: {
          variation: 'success',
          icon: 'check',
          text: this.$t('personalAssessment.assessmentCard.status.finalized'),
        },
      }[this.assessment.applicationStatus] || {}
    },
    applicationDate() {
      const { timeWindowStart, timeWindowEnd } = this.assessment
      let dateString = this.getDateString(timeWindowStart)

      if (timeWindowStart && timeWindowEnd
        && !this.equalDates(timeWindowStart, timeWindowEnd)) {
        dateString = dateString.concat(` - ${this.getDateString(timeWindowEnd)}`)
      }

      return dateString
    },
  },
  methods: {
    getDateString(date) {
      return new Date(date).toLocaleDateString('pt-br')
    },
    equalDates(start, end) {
      return this.getDateString(start) === this.getDateString(end)
    },
  },
}
</script>

<style lang="sass" scoped>
$card-info-title-height: 56px
$status-border-status: 100px
$custom-card-header-height: 72px
$custom-card-body-height: 100px

.assessment-card
  display: flex
  flex-direction: column
  justify-content: space-between
  background-image: url("~@/assets/card.svg") !important
  background-position: 0px -24px !important
  background-size: 100% !important

  +mq-s--mf
    margin-bottom: 0

  &:hover
    cursor: pointer
    box-shadow: $shadow-l $color-ink-lighter

  &.--loading
    background-color: $color-ink-lighter !important

  &__header
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    margin: $size-xxs $size-s
    color: $color-white
    height: auto
    min-height: auto

    +mq-s--mf
      margin: $size-s
      align-items: flex-start
      min-height: $custom-card-header-height

    &__info
      display: flex
      flex-direction: column
      padding: $size-s 0

      +mq-s--mf
        padding: 0

      &__icon
        display: none !important
        width: $size-l
        margin-bottom: $size-xs

        +mq-s--mf
          display: inline-block !important

      .header-info__title ~ .header-info__title
        margin-top: $size-xxs

  &__body
    display: flex
    flex-direction: column
    justify-content: space-between
    background-color: $color-white
    border-radius: 2 * $border-radius-m
    border-top-right-radius:  2 * $border-radius-s
    border-top-left-radius: 0
    padding: $size-s
    min-height: $custom-card-body-height !important
    height: 100%

    &__info

      .info-status
        margin-bottom: $size-s

        &__text
          font-size: $font-size-xs
          background-color: $color-ink-lightest
          padding: $size-xxs $size-xs
          border-radius: $status-border-status

      .info-title
        line-height: $size-m
        margin-bottom: $size-m

        +mq-s--mf
          height: $card-info-title-height

    &__application-range
      display: flex
      align-items: center
      font-size: $font-size-s
      color: $color-ink-light
      margin-top: $size-s

      i
        margin-right: $size-xs

    +mq-s--mf
      min-height: 2 * $custom-card-body-height

  .sas-skeleton-loader ~ .sas-skeleton-loader
      margin-top: $size-xs
</style>
