<template>
  <wrapper class="personal-assessments">
    <div class="personal-assessments__filters">
      <s-select
        v-model="selectedYear"
        class="personal-assessments__filters__years"
        :label="$t('personalAssessment.filters.years')"
        :options="applicationYears"
        @select="selectYear($event)"
      />
      <s-select
        v-model="selectedLecture"
        :label="$t('personalAssessment.filters.lectures')"
        :loading="loadingLectures"
        :options="lectures"
        track-by="name"
        @select="selectLecture($event)"
      />
    </div>
    <template v-if="loadingAssessments || assessments.length && !hasError">
      <div class="personal-assessments__cards-listing">
        <AssessmentCard
          v-for="assessment in assessments"
          :key="assessment.id"
          :assessment="assessment"
          :loading="loadingAssessments"
          @click.native="goToAssessment(assessment)"
        />
      </div>
      <SPagination
        v-if="assessments.length"
        ref="pagination"
        :page="pagination.page"
        :total-items="assessmentsCount"
        @page-change="pageChange()"
        @range-change="rangeChange()"
      >
        <template slot-scope="props">
          <slot
            :end="props.end"
            name="pagination"
            :start="props.start"
            :total="props.total"
          />
        </template>
      </SPagination>
    </template>
    <EmptyState
      v-else
      :comment="emptyState.comment"
      :image="emptyState.imageSrc"
      :warning="emptyState.warning"
    >
      <s-button
        v-if="hasError"
        icon-left="refresh-cw"
        @click="reload()"
      >
        {{ $t('commons.retry') }}
      </s-button>
    </EmptyState>
  </wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AssessmentCard from '../components/AssessmentCard'
import SPagination from '../components/SPagination'
import EmptyState from '../components/EmptyState'

import emptyStateImage from '../assets/assessments-empty-state.svg'

import services from '../services'

export default {
  name: 'PersonalAssessments',
  components: {
    AssessmentCard,
    SPagination,
    EmptyState,
  },
  data() {
    return {
      hasErrorLectures: false,
      hasErrorAssessments: false,
      loadingLectures: false,
      lectures: [
        {
          id: 0,
          name: this.$t('personalAssessment.filters.all'),
        },
      ],
      selectedYear: '2020',
      selectedLecture: null,
      loadingAssessments: false,
      assessments: [{}, {}],
      assessmentsCount: 0,
      pagination: {
        start: 0,
        page: 0,
        end: 10,
      },
      event: {
        category: 'AvaliaçõesEscola',
        action: 'Filtro',
        label: '',
      },
    }
  },
  computed: {
    ...mapGetters(['assessmentYears']),
    applicationYears() {
      return this.assessmentYears.map(({ year }) => String(year))
    },
    defaultLectureOption() {
      return {
        id: null,
        name: this.$t('personalAssessment.filters.all'),
      }
    },
    hasError() {
      return this.hasErrorLectures || this.hasErrorAssessments
    },
    emptyState() {
      return {
        imageSrc: emptyStateImage,
        warning: this.hasError
          ? this.$t('personalAssessment.error.warning')
          : this.$t('personalAssessment.assessmentsEmptyState.warning'),
        comment: this.hasError
          ? this.$t('personalAssessment.error.comment')
          : this.$t('personalAssessment.assessmentsEmptyState.comment'),
      }
    },
  },
  created() {
    this.loadLectures()

    if (this.assessmentYears.length === 0) {
      this.getAssessmentYears()
    }
  },
  methods: {
    ...mapActions([
      'fetchLectures',
      'getAssessmentYears',
    ]),
    reload() {
      if (this.hasErrorLectures) {
        this.loadLectures()
      } else if (this.hasErrorAssessments) {
        this.loadAssessments()
      }
    },
    async loadLectures() {
      this.hasErrorLectures = false
      this.retryVisible = false
      this.loadingAssessments = true
      this.loadingLectures = true

      try {
        const lectures = await this.fetchLectures()

        this.lectures = [...this.lectures, ...lectures]
        this.selectedLecture = this.defaultLectureOption

        await this.loadAssessments()
      } catch (error) {
        const statusError = error.response ? error.response.status : ''

        if (statusError.toString().match(/5\d{2}/i)) {
          this.retryVisible = true
        }

        this.$toasted.global.error({
          message: this.$t('personalAssessment.feedback.fetchLecturesError'),
        })

        this.hasErrorLectures = true
      } finally {
        this.loadingLectures = false
        this.loadingAssessments = false
      }
    },
    async loadAssessments(lectureId = null, page = 0) {
      this.hasErrorAssessments = false
      this.retryVisible = false
      this.loadingAssessments = true
      this.assessments = [{}, {}]

      try {
        const { data } = await services.fetchAssessments({
          lectureId,
          page,
          year: this.selectedYear,
        })

        this.assessments = data
      } catch (error) {
        const statusError = error.response ? error.response.status : ''

        if (statusError.toString().match(/5\d{2}/i)) {
          this.retryVisible = true
        }

        this.$toasted.global.error({
          message: this.$t('personalAssessment.feedback.fetchAssessmentsError'),
        })

        this.hasErrorAssessments = true
      } finally {
        this.loadingAssessments = false
      }
    },
    selectYear(year) {
      this.selectedYear = year
      this.pagination = {
        start: 0,
        page: 0,
        end: 10,
      }

      this.loadAssessments(
        this.selectedLecture.id || null,
      )
    },
    selectLecture(lecture) {
      this.selectedLecture = lecture || this.defaultLectureOption
      this.assessments = [{}, {}]
      this.loadAssessments(
        this.selectedLecture.id || null,
        this.pagination.page,
      )
      this.$trackEvent({
        ...this.event,
        label: 'Disciplinas',
      })
    },
    goToAssessment({
      assessmentId, applicationId, applicationStatus, exams,
    }) {
      this.$router.push({
        name: applicationStatus === 'DONE'
          ? 'assessment-report'
          : 'assessmentExams',
        params: {
          assessmentId,
          applicationId,
          examId: exams[0].id,
        },
      })
    },
    pageChange(page) {
      this.pagination.page = page
      this.loadAssessments(this.selectedLecture.id, page)
    },
    rangeChange(start, end) {
      this.pagination.start = start
      this.pagination.end = end
    },
  },
}
</script>

<style lang="sass" scoped>
$dropdown-max-height: 200px

.personal-assessments
  margin-top: $size-m

  +mq-m--mf
    margin-top: $size-xl

  &__filters
    display: flex
    flex-direction: column
    align-items: flex-start

    +mq-m--mf
      flex-direction: row
      align-items: center

    ::v-deep .sas-dropdown,
    ::v-deep .sas-dropdown-menu
      width: 100%

      +mq-s--mf
        width: 320px

    ::v-deep .sas-select
      width: 100%
      flex-direction: column

      +mq-s--mf
        width: 320px

    ::v-deep .sas-dropdown

      .sas-dropdown-menu
        position: fixed
        left: 0
        right: 0
        bottom: 0
        top: auto
        max-height: 100vh
        justify-content: unset
        overflow-y: auto

        &-enter,
        &-leave-to
          transform: translateY(100%)

        +mq-s--mf
          position: absolute
          bottom: auto
          max-height: $dropdown-max-height

          &-enter,
          &-leave-to
            transform: translateY(-10%)

    &__years
      margin-bottom: $size-s

      +mq-m--mf
        margin-bottom: 0
        margin-right: $size-s

  &__cards-listing
    display: grid
    grid-template-columns: 1fr
    grid-gap: $size-s
    margin: $size-xl 0

    +mq-s--mf
      grid-template-columns: repeat(2, 1fr)

    +mq-m--mf
      grid-template-columns: repeat(3, 1fr)
      grid-gap: $size-m

    +mq-l--mf
      grid-template-columns: repeat(4, 1fr)
</style>
